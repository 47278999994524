<template>
  <div
    class="InputSelectOptionOther">
    <p
      class="__label"
      v-show="!isOpen">
      {{ labelQuestion }}
      <button
        class="underline"
        type="button"
        @click="open">
        {{ labelCta }}
      </button>
    </p>

    <div
      class="mt-2"
      v-show="isOpen">
      <h5
        class="pb-1">
        {{ labelInstruction }}
      </h5>
      <InputText
        ref="input"
        :placeholder="placeholder"
        :is-placeholder-persistent="true"
        :model-value="modelValue"
        :is-invalid="isInvalid"
        @keyup="handleKeyup"
        @update:model-value="handleInput"
      />
    </div>
  </div>
</template>


<script>
import InputText from '@/components/InputText.vue';

export default {
  name: 'InputSelectOptionOther',

  components: {
    InputText,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    labelQuestion: {
      type: String,
      required: true,
    },
    labelCta: {
      type: String,
      required: true,
    },
    labelInstruction: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'submit',
    'update:modelValue',
  ],

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    open() {
      this.isOpen = true;
      this.$emit('update:modelValue', '');
      setTimeout(() => this.focus(), 100);
    },

    close() {
      this.isOpen = false;
    },

    handleInput(value) {
      this.$emit('update:modelValue', value);
    },

    handleKeyup(e) {
      const isEnter = e.key === 'Enter';
      if (isEnter) this.$emit('submit');
    },

    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.__label {
  padding-top: 16px;
  text-align: center;
}
</style>
